import { getCSRFToken } from "./common_functions";
import { Sidebar } from "@coreui/coreui";

import {
  initTrendingDiagrams,
  togglingDisplayTrendingsCharts,
} from "./trendings";

let locationQuery = window.location;
let charts = [];
function initDatepickers() {
  let date = document.getElementsByClassName("daterange");
  if (!!date.length) {
    let startDate = new Date().addDays(-31);
    const trendings = document.querySelector(".trendings");
    if (trendings) {
      startDate = new Date().addDays(-7);
    }
    const daterange = document.querySelector(".daterange.charts");
    if (daterange) {
      const setupConfig = {
        element: daterange,
        rangeOptions: { startDate: startDate },
        setup: {
          setup(picker) {
            picker.on("select", () => {
              // when we change date in datepicker and set its in charts - chart makes a shift in 1 day in labels.
              let dateFrom = picker.getStartDate("DD MMM YYYY");

              let dateTo = picker.getEndDate("DD MMM YYYY");
              if (new Date(dateFrom).getTime() < new Date(dateTo).getTime()) {
                setDatesToStorage(dateFrom, dateTo, "updated");
              } else if (
                new Date(dateFrom).getTime() === new Date(dateTo).getTime()
              ) {
                let options = {
                    year: "numeric",
                    month: "short",
                    day: "numeric",
                  },
                  formattedDateTo = new Date(dateTo)
                    .addDays(1)
                    .toLocaleDateString("en-BR", options);
                setDatesToStorage(dateFrom, formattedDateTo, "updated");
              }
              if (trendings) {
                dateFrom = new Date(dateFrom).addDays(-1);
                togglingDisplayTrendingsCharts();
                initTrendingDiagrams(dateFrom, dateTo);
              } else {
                for (let i = 0; i < charts.length; i++) {
                  charts[i].destroy();
                }
                document.querySelector(".sk-wave")?.classList.add("active");
                initDiagrams(dateFrom, dateTo);
              }
            });
          },
        },
      };

      initDatePicker(setupConfig);
    }
  }
}

function initDiagrams(dateFrom = new Date().addDays(-31), dateTo = new Date()) {
  let canvases = document.getElementsByTagName("canvas");
  if (canvases.length > 0) {
    let dates = getDatesFromStorage(),
      baseUrl = locationQuery.pathname.split("/"),
      dateParams = "?date_from=" + dateFrom + "&date_to=" + dateTo;

    baseUrl.pop(); // removed analytic_charts from url

    fetch(
      locationQuery.origin + baseUrl.join("/") + "/chart_info" + dateParams,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "X-Csrf-Token": getCSRFToken(),
        },
      }
    )
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        Array.from(canvases).forEach(function (canvas) {
          canvas.style.display = "block";
        });
        let noDataElements = document.querySelectorAll(".no-data");
        Array.from(noDataElements).forEach(function (noDataElement) {
          noDataElement.style.display = "none";
        });

        initAppAcceptedChart(data.app_accepted_by_date);
        initAppApprovedAcceptedChart(data.apps_by_different_statuses);
        initAppAcceptedDeclinedChart(data.apps_by_different_statuses);
        initAppApprovedDeclinedChart(data.apps_by_different_statuses);
        initAppAmountEarned(data.apps_by_different_statuses);
      })
      .catch(function (error) {
        console.error("Error fetching data:", error);
      });
  }
}

function initAppAcceptedChart(apps) {
  let canvasID = "app-accepted",
    canvas = document.getElementById(canvasID),
    ctx = canvas.getContext("2d"),
    datesFromParams = getDatesFromStorage(),
    dateFrom = datesFromParams.from,
    dateTo = datesFromParams.to,
    datasets = [],
    providerKeys = titleizeProviders(Object.keys(apps));

  let labels = getDates(dateFrom, dateTo);

  providerKeys.forEach((key, i) => {
    let countOfApps = [],
      downCaseKey = key.toLowerCase();
    labels.forEach((label, i) => {
      countOfApps.push(apps[downCaseKey][label] || 0);
    });
    let newDataset = {
      label: key,
      backgroundColor: setColor(downCaseKey),
      borderColor: setColor(downCaseKey),
      data: countOfApps,
      fill: false,
    };
    datasets.push(newDataset);
  });
  removePreloader(canvasID);
  if (datasets.length === 0) {
    displayNoDataInsteadOfChart(canvasID);
  }
  drawChart(ctx, "line", labels, datasets, 1);
}

function initAppApprovedDeclinedChart(apps) {
  setDataByStatuses("approved-declined", apps, "approved", "declined", [
    "Approved",
    "Declined",
  ]);
}

function initAppApprovedAcceptedChart(apps) {
  let canvasID = "approved-accepted",
    canvas = document.getElementById(canvasID),
    ctx = canvas.getContext("2d"),
    providerKeys = Object.keys(apps),
    labels = titleizeProviders(providerKeys),
    status1 = [],
    status2 = [],
    status3 = [];

  providerKeys.forEach((key, i) => {
    status1.push(apps[key]["approved"] || 0);
    status2.push(apps[key]["accepted"] || 0);
    status3.push(apps[key]["approved_but_not_accepted"] || 0);
  });

  let datasets = [
    {
      label: "Approved",
      backgroundColor: setBarColor("dark"),
      borderColor: setBarColor("dark"),
      borderWidth: 1,
      data: status1,
    },
    {
      label: "Accepted",
      backgroundColor: setBarColor(),
      borderColor: setBarColor(),
      data: status2,
    },
    {
      label: "Approved but not Accepted",
      backgroundColor: setBarColor("light"),
      borderColor: setBarColor("light"),
      borderWidth: 1,
      data: status3,
    },
  ];

  removePreloader(canvasID);
  if (providerKeys.length === 0) {
    displayNoDataInsteadOfChart(canvasID);
  }
  drawChart(ctx, "horizontalBar", labels, datasets, 1);
}

function initAppAcceptedDeclinedChart(apps) {
  setDataByStatuses("accepted-declined", apps, "accepted", "declined", [
    "Accepted",
    "Declined",
  ]);
}

function initAppAmountEarned(apps) {
  let canvas = document.getElementById("amount-earned"),
    ctx = canvas.getContext("2d"),
    providerKeys = Object.keys(apps),
    labels = titleizeProviders(providerKeys),
    colors = [],
    datasets = [];

  providerKeys.forEach((label, i) => {
    datasets.push(apps[label]["amount_earned"] || 0);
    colors.push(setColor(label));
  });
  let newDatasets = {
    type: "pie",
    data: {
      datasets: [
        {
          data: datasets,
          backgroundColor: colors,
        },
      ],
      labels: labels,
    },
    options: {
      responsive: true,
      tooltips: {
        enabled: true,
        callbacks: {
          label: function (tooltipItem, data) {
            let label = " " + data.labels[tooltipItem.index] || "",
              sum = 0,
              value = 0;
            if (label) {
              label += ": ";
            }
            sum = data.datasets[0].data.reduce((memo, value) => {
              return memo + value;
            });
            value =
              data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];

            if (sum > 0) {
              label += Number((value / sum) * 100).toFixed(2) + "%";
            } else {
              label += "0%";
            }

            return label;
          },
        },
      },
    },
  };

  removePreloader("amount-earned");
  if (datasets.length === 0) {
    displayNoDataInsteadOfChart("amount-earned");
  } else {
    let amountEarnedChart = new Chart(ctx, newDatasets);
    charts.push(amountEarnedChart);
  }
}

function setDataByStatuses(
  canvasID,
  apps,
  firstStatus,
  secondStatus,
  legendsArr
) {
  let canvas = document.getElementById(canvasID),
    ctx = canvas.getContext("2d"),
    providerKeys = Object.keys(apps),
    labels = titleizeProviders(providerKeys),
    status1 = [],
    status2 = [];

  providerKeys.forEach((key, i) => {
    status1.push(apps[key][firstStatus] || 0);
    status2.push(apps[key][secondStatus] || 0);
  });

  let datasets = [
    {
      label: legendsArr[0],
      backgroundColor: setBarColor("dark"),
      borderColor: setBarColor("dark"),
      borderWidth: 1,
      data: status1,
    },
    {
      label: legendsArr[1],
      backgroundColor: setBarColor("light"),
      borderColor: setBarColor("light"),
      data: status2,
    },
  ];

  removePreloader(canvasID);
  if (providerKeys.length === 0) {
    displayNoDataInsteadOfChart(canvasID);
  }
  drawChart(ctx, "horizontalBar", labels, datasets, 1);
}

function drawChart(ctx, type, labels, dataset, stepSize) {
  let newChart = new Chart(ctx, {
    type: type,
    data: {
      labels: labels,
      datasets: dataset,
    },
    options: {
      scales: {
        yAxes: [
          {
            ticks: {
              beginAtZero: false,
              stepSize: stepSize,
            },
          },
        ],
        xAxes: [
          {
            ticks: {
              beginAtZero: true,
              stepSize: 1,
            },
          },
        ],
      },
    },
  });
  charts.push(newChart);
}

function removePreloader(canvasId) {
  let parent = document.getElementById(canvasId).parentElement;
  parent.querySelector(".sk-wave")?.classList.remove("active");
}

function setColor(provider) {
  let hash = 0;
  for (let i = 0; i < provider.length; i++) {
    hash = provider.charCodeAt(i) + ((hash << 5) - hash);
  }
  let color = (hash & 0x00ffffff).toString(16).toUpperCase();

  return "00000".substring(0, 6 - color.length) + color;
}

function setBarColor(bright = "") {
  let color = "";
  switch (bright) {
    case "dark":
      color = "#36A2EB";
      break;
    case "light":
      color = "#C6DEED";
      break;
    default:
      color = "#83BFE8";
  }

  return color;
}

function titleizeProviders(providers) {
  let result = [];
  providers.forEach((word, i) => {
    let capitalizedWord = word.charAt(0).toUpperCase() + word.slice(1);
    result.push(capitalizedWord);
  });
  return result;
}

function displayNoDataInsteadOfChart(canvasId) {
  let parentElement = document.getElementById(canvasId).parentElement;
  let canvasElement = document.getElementById(canvasId);
  let noDataElement = parentElement.querySelector(".no-data");
  canvasElement.style.display =
    canvasElement.style.display === "none" ? "block" : "none";
  noDataElement.style.display =
    noDataElement.style.display === "none" ? "block" : "none";
}

function onlyOneFlexshopper(obj, status) {
  if (obj.classList.contains("flexshopper") && status === "Active") {
    let self = obj.closest("tbody").querySelector(".flexshopper2");
    compareFlexshopperStatuses(self, status);
  }
  if (obj.classList.contains("flexshopper2") && status === "Active") {
    let self = obj.closest("tbody").querySelector(".flexshopper");
    compareFlexshopperStatuses(self, status);
  }
}

function compareFlexshopperStatuses(self, status) {
  if (
    self.closest("tr").querySelector(".provider_status").textContent === status
  ) {
    self.click();
  }
}

document.addEventListener("turbo:load", function () {
  initDatepickers();
  setDatesToStorage();

  if (document.getElementById("charts")) {
    initDiagrams();
  }

  document.body.addEventListener("click", function (event) {
    if (event.target.closest(".head td:not(.non-collapsible)")) {
      event.target.closest(".head").nextElementSibling.classList.toggle("show");
    }
  });

  document
    .querySelector(".sms_switcher")
    ?.addEventListener("change", function () {
      fetch("/settings/sms_service", {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "X-Csrf-Token": getCSRFToken(),
        },
      }).catch((error) => console.error(error));
    });

  document
    .querySelector(".force_resets_switcher")
    ?.addEventListener("change", function () {
      fetch("/settings/force_resets", {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "X-Csrf-Token": getCSRFToken(),
        },
      }).catch((error) => console.error(error));
    });

  document
    .querySelector(".multiple_iframes_switcher")
    ?.addEventListener("change", function () {
      fetch("/settings/multiple_iframes", {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "X-Csrf-Token": getCSRFToken(),
        },
      }).catch((error) => console.error(error));
    });

  document.querySelectorAll(".provider_state").forEach(function (elem) {
    elem.addEventListener("change", function () {
      let leaseID = this.getAttribute("data-id");
      let self = this;

      fetch("/lp_configs/" + leaseID + "/activation_provider", {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "X-Csrf-Token": getCSRFToken(),
        },
      })
        .then(function (response) {
          return response.json();
        })
        .then(function (data) {
          let status = data.provider.status;
          onlyOneFlexshopper(self, status);
          self.closest("tr").querySelector(".provider_status").innerHTML =
            status;
        })
        .catch((error) => console.error(error));
    });
  });

  const sidebar = document.querySelector(".c-sidebar");

  sidebar && new Sidebar(sidebar, {});
});
